import React from 'react'
import styles from './SessionFaq.module.css'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery } from '@mui/material'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded.MuiAccordionSummary-root': {
    backgroundColor: 'rgba(239, 239, 239, 1)',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  fontWeight: 700,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'rgba(59, 49, 125, 1)',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  whiteSpace: 'pre-line',
}))

interface Props {
  items: any[]
  cta?: {
    title: string
    description: string
    email: string
  }
}

export const SessionFaq: React.FC<Props> = ({ items, cta }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div id="faqs" className={styles.container}>
      <div className={styles.title}>FAQs</div>
      <div
        className={styles.content}
        style={{
          maxWidth: '800px',
        }}
      >
        {items.map((qa, index) => (
          <Accordion key={index}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              {index + 1}. {qa.question}
            </AccordionSummary>
            <AccordionDetails>{qa.answer}</AccordionDetails>
          </Accordion>
        ))}

        <div
          style={{
            marginTop: '60px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <h1 style={{ fontSize: isMobile ? '30px' : '48px' }}>
            {cta?.title || 'Have more questions?'}
          </h1>
          <p className={styles.description}>
            {cta?.description ||
              'If you have any questions about this program please reach out to'}
            <br />
            <a href={`mailto:${cta?.email || 'hello@mentem.co'}`}>
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                {cta?.email || 'hello@mentem.co'}
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
