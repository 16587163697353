import { useDataContext } from '../context/DataProvider'
import styles from './Home.module.css'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '@egjs/react-flicking/dist/flicking.css'
import { SessionHero } from 'components/SessionHero'
import { SessionBoxes } from 'components/SessionBoxes'
import { SessionBlocks } from 'components/SessionBlocks'
import { Footer } from 'components/Footer'
import { SessionDuplex } from 'components/SessionDuplex'
import AppBar from 'components/AppBar'
import { SessionCTA } from 'components/SessionCTA'
import { SessionSchedule } from 'components/SessionSchedule'
import { SessionScheduleTable } from 'components/SessionScheduleTable'
import { SessionFaq } from 'components/SessionFaq'
import { Helmet } from 'react-helmet'
import { GenericLoadingIndicator } from 'components/GenericLoadingIndicator'

export default function Home() {
  const { checkProgram, program } = useDataContext()
  const { clientCode, programCode } = useParams()

  useEffect(() => {
    console.log('Home:', clientCode, programCode)
    let tmpClientCode = clientCode
    if (!tmpClientCode) {
      tmpClientCode = 'cba'
    }
    checkProgram(tmpClientCode, programCode)
  }, [checkProgram, clientCode, programCode])

  return (
    <div id="main" className={styles.container}>
      {program && program?.name && (
        <Helmet>
          <title>{program?.name}</title>
        </Helmet>
      )}
      <AppBar />
      {program && (
        <>
          <div id="content" style={{ position: 'relative' }}>
            <SessionHero
              title={program?.components?.sessionHero?.title || ''}
              subtitle={program?.components?.sessionHero?.subtitle || ''}
              background={program?.components?.sessionHero?.background || ''}
              backgroundMobile="https://content.mentem.co/eoi/cba/advanced-analytics/banner-m.jpg"
              titleColor="rgba(40, 41, 120, 1)"
              subtitleColor="rgba(186, 97, 255, 1)"
              titleSize="30px"
              subtitleSize="24px"
            />
            <SessionCTA />

            <SessionDuplex
              id="testimonials"
              backgroundColor="rgba(40, 41, 120, 1)"
              title={program?.components?.sessionVideo?.title || ''}
              description={program?.components?.sessionVideo?.description || ''}
              titleColor="rgba(255,244,4,1)"
              descriptionColor="rgba(255,255,255,1)"
              image={program?.components?.sessionVideo?.videoThumbnail || ''}
              videoUrl={program?.components?.sessionVideo?.videoUrl || ''}
            />
            <img
              src="https://content.mentem.co/eoi/_share/top.jpg"
              alt="top"
              style={{ position: 'absolute', width: '100%' }}
            />

            <SessionSchedule
              titleColor="rgba(40,41,120,1)"
              backgroundNumberColor="rgba(40, 41, 120, 1)"
              numberColor="rgba(255,247,1,1)"
              numberSize="60px"
              closeDateTextColor="rgba(40,41,120,1)"
              closeDateColor="rgba(186, 97, 255, 1)"
              progressBorderColor="rgba(40, 41, 120, 1)"
              progressBarColor="rgba(255, 244, 4, 1)"
              upcomingProgramsColor="rgba(186, 97, 255, 1)"
              upcomingPrograms={
                program?.components?.sessionSchedule?.upcomingPrograms || []
              }
            />

            <SessionScheduleTable
              backgroundColor="rgba(0,0,0,1)"
              clientLogo="https://content.mentem.co/eoi/cba/cba.png"
              head={program?.components?.sessionScheduleTable?.head || {}}
              rows={program?.components?.sessionScheduleTable?.rows || []}
            />
            <SessionBlocks
              title={program?.components?.sessionBrochure?.title || ''}
              description={
                program?.components?.sessionBrochure?.description || ''
              }
              brochureThumbnail={
                program?.components?.sessionBrochure?.brochureThumbnail || ''
              }
              brochurePdf={
                program?.components?.sessionBrochure?.brochurePdf || ''
              }
              syllabusPdf={
                program?.components?.sessionBrochure?.syllabusPdf || ''
              }
              topics={program?.components?.sessionDemoTopics.items || []}
            />
          </div>
          <SessionBoxes />
          <img
            src="https://content.mentem.co/eoi/_share/bottom.jpg"
            alt="bottom"
            style={{
              position: 'absolute',
              width: '100%',
              bottom: '210px',
              zIndex: -1,
            }}
          />
          <SessionFaq
            items={program?.components?.sessionFaq?.items}
            cta={program?.components?.sessionFaq?.cta}
          />
          <Footer backgroundColor="#000" />
        </>
      )}
      {!program && (
        <div className={styles.center}>
          <GenericLoadingIndicator />
        </div>
      )}
    </div>
  )
}
